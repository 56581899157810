.my-hide {
	display: none !important;
}

.zero-margin-bottom {
	margin-bottom: 0 !important;
}

.aplayer * {
	color: #666;
}

/*折叠时小标签*/
#waifu-toggle {
	background-color: #206051; /*折叠时小标签背景颜色*/
	border-radius: 5px;
	bottom: 66px;
	color: #fff; /*折叠时小标签文本颜色*/
	cursor: pointer;
	font-size: 12px;
	left: 7px;
	margin-left: -100px;
	padding: 5px 0px 5px 5px;
	position: fixed;
	transition: margin-left 1s;
	width: 60px;
	writing-mode: vertical-rl;
}

#waifu-toggle.waifu-toggle-active {
	margin-left: -50px;
}

#waifu-toggle.waifu-toggle-active:hover {
	margin-left: -30px;
}

#waifu {
	bottom: -1000px;
	left: 0;
	line-height: 0;
	position: fixed;
	transform: translateY(3px);
	transition: transform .3s ease-in-out, bottom 3s ease-in-out;
	z-index: 1;
}

#waifu:hover {
	transform: translateY(0);
}

@media (max-width: 768px) {
	#waifu {
		display: none;
	}
}

/*对话框*/
#waifu-tips {
	animation: shake 50s ease-in-out 5s infinite;
	background-color: rgb(255, 255, 255, .62);
	border-radius: 12px;
	box-shadow: 0 0px 15px 0px #ffffff;
	font-size: 15px;
/* 	font-weight: bold; */
	line-height: 24px;
	color: #4a174d;
	margin: auto;
	width: max-content;
	min-height: max-content;
	max-width: 250px;
	opacity: 0;
	overflow: hidden;
	padding: 5px 10px;
	top:-20px;
	text-overflow: ellipsis;
	transition: opacity 1s;
	word-break: break-all;
}

#waifu-tips.waifu-tips-active {
	opacity: 1;
	transition: opacity .2s;
}

#waifu-tips span {
	color: #206051;
	/*color: #0099cc;*/
}

#waifu #live2d {
	cursor: grab;
	height: 250px;
	position: relative;
	width: 250px;
}

#waifu #live2d:active {
	cursor: grabbing;
}

/*工具栏*/
#waifu-tool {
	color: #aaa;
	opacity: 0;
	position: absolute;
	left: 10px;
	bottom: 70px;
	transition: opacity 1s;
}

#waifu:hover #waifu-tool {
	opacity: 1;
}

#waifu-tool span {
	color: #5b6c7d;
	cursor: pointer;
	display: block;
	line-height: 30px;
	text-align: center;
	transition: color .3s;
}

#waifu-tool span:hover {
	color: #0684bd; /* #34495e */
}

@keyframes shake {
	2% {
		transform: translate(.5px, -1.5px) rotate(-.5deg);
	}

	4% {
		transform: translate(.5px, 1.5px) rotate(1.5deg);
	}

	6% {
		transform: translate(1.5px, 1.5px) rotate(1.5deg);
	}

	8% {
		transform: translate(2.5px, 1.5px) rotate(.5deg);
	}

	10% {
		transform: translate(.5px, 2.5px) rotate(.5deg);
	}

	12% {
		transform: translate(1.5px, 1.5px) rotate(.5deg);
	}

	14% {
		transform: translate(.5px, .5px) rotate(.5deg);
	}

	16% {
		transform: translate(-1.5px, -.5px) rotate(1.5deg);
	}

	18% {
		transform: translate(.5px, .5px) rotate(1.5deg);
	}

	20% {
		transform: translate(2.5px, 2.5px) rotate(1.5deg);
	}

	22% {
		transform: translate(.5px, -1.5px) rotate(1.5deg);
	}

	24% {
		transform: translate(-1.5px, 1.5px) rotate(-.5deg);
	}

	26% {
		transform: translate(1.5px, .5px) rotate(1.5deg);
	}

	28% {
		transform: translate(-.5px, -.5px) rotate(-.5deg);
	}

	30% {
		transform: translate(1.5px, -.5px) rotate(-.5deg);
	}

	32% {
		transform: translate(2.5px, -1.5px) rotate(1.5deg);
	}

	34% {
		transform: translate(2.5px, 2.5px) rotate(-.5deg);
	}

	36% {
		transform: translate(.5px, -1.5px) rotate(.5deg);
	}

	38% {
		transform: translate(2.5px, -.5px) rotate(-.5deg);
	}

	40% {
		transform: translate(-.5px, 2.5px) rotate(.5deg);
	}

	42% {
		transform: translate(-1.5px, 2.5px) rotate(.5deg);
	}

	44% {
		transform: translate(-1.5px, 1.5px) rotate(.5deg);
	}

	46% {
		transform: translate(1.5px, -.5px) rotate(-.5deg);
	}

	48% {
		transform: translate(2.5px, -.5px) rotate(.5deg);
	}

	50% {
		transform: translate(-1.5px, 1.5px) rotate(.5deg);
	}

	52% {
		transform: translate(-.5px, 1.5px) rotate(.5deg);
	}

	54% {
		transform: translate(-1.5px, 1.5px) rotate(.5deg);
	}

	56% {
		transform: translate(.5px, 2.5px) rotate(1.5deg);
	}

	58% {
		transform: translate(2.5px, 2.5px) rotate(.5deg);
	}

	60% {
		transform: translate(2.5px, -1.5px) rotate(1.5deg);
	}

	62% {
		transform: translate(-1.5px, .5px) rotate(1.5deg);
	}

	64% {
		transform: translate(-1.5px, 1.5px) rotate(1.5deg);
	}

	66% {
		transform: translate(.5px, 2.5px) rotate(1.5deg);
	}

	68% {
		transform: translate(2.5px, -1.5px) rotate(1.5deg);
	}

	70% {
		transform: translate(2.5px, 2.5px) rotate(.5deg);
	}

	72% {
		transform: translate(-.5px, -1.5px) rotate(1.5deg);
	}

	74% {
		transform: translate(-1.5px, 2.5px) rotate(1.5deg);
	}

	76% {
		transform: translate(-1.5px, 2.5px) rotate(1.5deg);
	}

	78% {
		transform: translate(-1.5px, 2.5px) rotate(.5deg);
	}

	80% {
		transform: translate(-1.5px, .5px) rotate(-.5deg);
	}

	82% {
		transform: translate(-1.5px, .5px) rotate(-.5deg);
	}

	84% {
		transform: translate(-.5px, .5px) rotate(1.5deg);
	}

	86% {
		transform: translate(2.5px, 1.5px) rotate(.5deg);
	}

	88% {
		transform: translate(-1.5px, .5px) rotate(1.5deg);
	}

	90% {
		transform: translate(-1.5px, -.5px) rotate(-.5deg);
	}

	92% {
		transform: translate(-1.5px, -1.5px) rotate(1.5deg);
	}

	94% {
		transform: translate(.5px, .5px) rotate(-.5deg);
	}

	96% {
		transform: translate(2.5px, -.5px) rotate(-.5deg);
	}

	98% {
		transform: translate(-1.5px, -1.5px) rotate(-.5deg);
	}

	0%, 100% {
		transform: translate(0, 0) rotate(0);
	}
}
